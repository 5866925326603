import isNil from 'lodash/isNil'

import { operationIsOpen } from '@publica/common'
import { FC } from '@publica/ui-common-utils'
import { usePollingRate } from '@publica/ui-web-state'
import { useAttachmentsWithUploads } from '@publica/ui-web-utils'

import { useGetAttachmentsForParticipantQuery } from '../../../data'
import { useParticipationContext } from '../context'
import { AttachmentsIndex } from './AttachmentsIndex'

export const Attachments: FC = () => {
    const ctx = useParticipationContext()

    const { data, loading } = useGetAttachmentsForParticipantQuery({
        variables: {
            participantId: ctx.participant.id,
        },
        pollInterval: usePollingRate(),
    })

    const participant = data?.participant
    const operation = participant?.operation

    const uploadedAttachments = participant?.uploadedAttachments ?? []
    const attachments = participant?.attachments ?? []
    const readonly = isNil(operation) || !operationIsOpen(operation)

    const attachmentsWithUploaded = useAttachmentsWithUploads(attachments, uploadedAttachments, readonly)

    return <AttachmentsIndex loading={loading} attachments={attachmentsWithUploaded} />
}
