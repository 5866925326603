import { FileOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { NotFound, OperationHeader } from '@publica/ui-web-components'
import { useTabs } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useGetOperationForParticipantSuspenseQuery } from '../../../data'
import { Attachments } from '../attachments'
import { ParticipationContext } from '../context'
import { Documents } from '../documents'
import { Profile } from '../profile'

const useTabsTranslation = createUseTranslation({
    EN: {
        profile: 'Information',
        attachments: 'KYC Documents',
        documents: 'Documents',
    },
    FR: {
        profile: 'Informations Personelles',
        attachments: 'Documents KYC',
        documents: 'Documents',
    },
})

export const Participation: FC = () => {
    const participantId = useParticipantId()
    const { t } = useTabsTranslation()

    const { data } = useGetOperationForParticipantSuspenseQuery({
        variables: {
            participantId,
        },
    })

    const tabs = useTabs(
        useMemo(
            () => [
                {
                    key: 'profile',
                    label: t('profile'),
                    content: <Profile />,
                    icon: <UserOutlined />,
                },
                {
                    key: 'attachments',
                    label: t('attachments'),
                    content: <Attachments />,
                    icon: <PaperClipOutlined />,
                },
                {
                    key: 'documents',
                    label: t('documents'),
                    content: <Documents />,
                    icon: <FileOutlined />,
                },
            ],
            [t]
        )
    )

    const participant = data?.participant

    const header = useMemo(() => {
        if (isNil(participant)) {
            return null
        }

        return <OperationHeader operation={participant?.operation} participant={participant} />
    }, [participant])

    if (isNil(participant)) {
        return <NotFound />
    }

    const operation = participant.operation

    return (
        <ParticipationContext participant={participant} operation={operation}>
            <Card title={header}>
                <Tabs
                    defaultActiveKey="profile"
                    activeKey={tabs.activeTab}
                    onTabClick={tabs.onChange}
                    items={tabs.items}
                />
            </Card>
        </ParticipationContext>
    )
}

const useParticipantId = () => {
    const { participantId } = useParams()
    assert.defined(participantId)
    return participantId
}
