import { Button, Card, Col, Divider, Form, Result, Row, Space } from 'antd'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { GraphQLValue, formValuesToGraphQLValueInputs, graphQLValuesToFormValues } from '@publica/render'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, EditParticipantValues, Spinner } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import {
    FieldGroup,
    FieldParameters,
    GetValuesForParticipantDocument,
    LocalizedString,
    OperationStatus,
    useGetValueFormForParticipantQuery,
    useUpdateParticipantValuesMutation,
} from '../../../../data'
import { useParticipationContext } from '../../context'

const fieldGroups: FieldGroup[] = ['PERSONAL_INFORMATION', 'BANKING_INFORMATION']

const useEditProfileStyles = createUseStyles({
    form: {
        marginTop: 40,
        '& .ant-picker': {
            width: '100%',
        },
    },
    submit: {
        textAlign: 'right',
    },
})

export const EditProfile: FC = () => {
    const ctx = useParticipationContext()

    const { data, loading } = useGetValueFormForParticipantQuery({
        variables: { participantId: ctx.participant.id },
        pollInterval: usePollingRate(),
    })

    const participant = data?.participant

    if (isNil(participant) || loading) {
        return <Spinner />
    }

    const operation = participant?.operation

    return <EditProfileCard operation={operation} participant={participant} />
}

type Field = {
    key: string
    name: LocalizedString
    parameters: FieldParameters
    position?: number | null
    group: FieldGroup
}

type FormSection = {
    id: string
    name: LocalizedString
}

type FieldWithFormSection = Field & {
    formSection?: FormSection | null
}

type Operation = {
    status: OperationStatus
}

type Participant = {
    id: string
    values: GraphQLValue[]
}

const useEditProfileCardTranslation = createUseTranslation({
    FR: {
        closed: `L'opération est clôturée`,
        cannotModify: `Vous ne pouvez plus modifier votre fiche d'informations`,
        update: `Compléter votre fiche d'informations`,
    },
    EN: {
        closed: 'The operation has closed',
        cannotModify: `You can no longer update your information`,
        update: `Update your personal information`,
    },
})

type EditProfileCardProps = {
    operation: Operation
    participant: Participant & { fields: FieldWithFormSection[] }
}

const EditProfileCard: FC<EditProfileCardProps> = ({ operation, participant }) => {
    const navigate = useNavigate()
    const { t } = useEditProfileCardTranslation()

    const styles = useEditProfileStyles()
    const filteredFields = useMemo(
        () => participant.fields.filter(field => fieldGroups.includes(field.group)),
        [participant.fields]
    )
    const fields = useMemo(() => sortBy(filteredFields, field => field.position), [filteredFields])

    const closed = useMemo(() => {
        // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
        const back = () => navigate('..')
        // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
        const extra = [
            // eslint-disable-next-line react/jsx-no-bind
            <ActionButton key="back" size="middle" onClick={back}>
                {t('back')}
            </ActionButton>,
        ]

        return (
            <Card>
                <Result title={t('closed')} subTitle={t('cannotModify')} extra={extra} />
            </Card>
        )
    }, [navigate, t])

    if (!operationIsOpen(operation)) {
        return closed
    }

    return (
        <Card title={t('update')}>
            <Row justify="center" className={styles.form}>
                <Col span={14}>
                    <EditProfileForm fields={fields} participant={participant} />
                </Col>
            </Row>
        </Card>
    )
}

const useEditProfileFormTranslation = createUseTranslation({
    EN: {
        update: 'Update',
    },
    FR: {
        update: `Mettre à jour vos informations`,
    },
})

type EditProfileFormProps = {
    fields: FieldWithFormSection[]
    participant: Participant
}

const EditProfileForm: FC<EditProfileFormProps> = ({ fields, participant }) => {
    const styles = useEditProfileStyles()
    const { t } = useEditProfileFormTranslation()
    const [form] = Form.useForm()

    const [updateParticipantValuesMutation, { loading }] = useUpdateParticipantValuesMutation({
        refetchQueries: [GetValuesForParticipantDocument],
    })

    const navigate = useNavigate()
    const back = useCallback(() => {
        navigate('..')
    }, [navigate])

    const submit = useAsyncCallback(async () => {
        try {
            await form.validateFields()
        } catch (e) {
            return
        }

        const values = formValuesToGraphQLValueInputs(form.getFieldsValue(), fields)

        await updateParticipantValuesMutation({
            variables: {
                participantValues: [
                    {
                        id: participant.id,
                        values,
                    },
                ],
            },
        })

        navigate('..')
    }, [fields, form, navigate, participant.id, updateParticipantValuesMutation])

    const filteredFields = useMemo(() => fields.filter(field => fieldGroups.includes(field.group)), [fields])
    const values = useMemo(
        () => graphQLValuesToFormValues(participant.values, filteredFields),
        [filteredFields, participant.values]
    )

    return (
        <>
            <EditParticipantValues values={values} fields={filteredFields} form={form} />
            <Divider />
            <div className={styles.submit}>
                <Space>
                    <Button onClick={back} disabled={loading}>
                        {t('cancel')}
                    </Button>
                    <ActionButton inProgress={loading} size="middle" onClick={submit}>
                        {t('update')}
                    </ActionButton>
                </Space>
            </div>
        </>
    )
}
