import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { EditProfile } from './EditProfile'
import { ShowProfile } from './ShowProfile'

const showProfile = <ShowProfile />
const editProfile = <EditProfile />

export const Profile: FC = () => {
    return (
        <Routes>
            <Route index element={showProfile} />
            <Route path="profile" element={showProfile} />
            <Route path="profile/edit" element={editProfile} />
        </Routes>
    )
}
