import { Alert } from 'antd'
import { createUseStyles } from 'react-jss'

import { useCurrentAccount } from '@publica/ui-common-auth'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { Account } from '../Account'

const useTranslation = createUseTranslation({
    FR: {
        missingInfo: `Vous devez compléter vos informations de compte avant de pouvoir poursuivre`,
    },
    EN: {
        missingInfo: 'You must provide the required information before continuing',
    },
})

const useStyles = createUseStyles({
    alert: {
        marginBottom: 20,
    },
})

export const AccountInfoGuard: FC = ({ children }) => {
    const account = useCurrentAccount()
    const styles = useStyles()
    const { t } = useTranslation()

    if (account.phoneNumber === null) {
        return (
            <>
                <Alert message={t('missingInfo')} type="warning" showIcon className={styles.alert} />
                <Account allowCancel={false} />
            </>
        )
    }

    return <>{children}</>
}
