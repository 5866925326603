import { Button, Card, Divider, Empty, Space } from 'antd'
import orderBy from 'lodash/orderBy'
import { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, FilterColumnType, FilterTable, Spinner, VerticalSpacer } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { OperationStatus, useGetParticipationsForCurrentAccountQuery } from '../../../data'
import { Notification } from '../../types'
import { OperationCard } from './OperationCard'

type Operation = {
    id: string
    name: string
    status: OperationStatus
}

type Participant = {
    id: string
    info: {
        name: string
    }
    operation: Operation
    notifications: Notification[]
}

// FIXME-P1(manager-ui): redesign landing
export const Landing: FC = () => {
    const { data, loading } = useGetParticipationsForCurrentAccountQuery({ pollInterval: usePollingRate() })

    const participants = useMemo(
        () =>
            orderBy(data?.currentAccount.participations ?? [], participant => participant.operation.createdAt, 'desc'),
        [data?.currentAccount.participations]
    )

    const openOperations = useMemo<Participant[]>(
        () => participants.filter(participant => participant.operation.status === 'OPEN'),
        [participants]
    )

    const closedOperations = useMemo(
        () =>
            participants
                .filter(participant => participant.operation.status === 'CLOSED')
                .map(participant => participant.operation),
        [participants]
    )

    const hasOpenOperations = openOperations.length > 0

    if (loading || data === undefined) {
        return <Spinner />
    }

    return (
        <>
            <OpenOperations participations={openOperations} />
            <ClosedOperations operations={closedOperations} showClosedByDefault={!hasOpenOperations} />
        </>
    )
}

const useOpenOperationsStyles = createUseStyles({
    empty: {
        margin: [50, 0],
    },
})

const openOperationsTranslation = createUseTranslation({
    FR: {
        noActiveOperations: `Vous ne participez à aucune opération active`,
    },
    EN: {
        noActiveOperations: 'You are not participating in any active operations',
    },
})

type OpenOperationsProps = {
    participations: Participant[]
}

const OpenOperations: FC<OpenOperationsProps> = ({ participations }) => {
    const styles = useOpenOperationsStyles()
    const { t } = openOperationsTranslation()

    if (participations.length === 0) {
        return <Empty className={styles.empty} description={t('noActiveOperations')} />
    }

    return (
        <VerticalSpacer>
            {participations.map(participant => (
                <OperationCard
                    key={participant.operation.id}
                    operation={participant.operation}
                    participant={participant}
                />
            ))}
        </VerticalSpacer>
    )
}

const useClosedOperationsTranslation = createUseTranslation({
    FR: {
        hideOperations: `Masquer les opérations clôturées`,
        showOperations: `Afficher les opérations clôturées`,
        closedOperations: `Opérations clôturées`,
        accessDocuments: `Accéder aux documents`,
    },
    EN: {
        hideOperations: 'Hide closed operations',
        showOperations: 'Show closed operations',
        closedOperations: 'Closed operations',
        accessDocuments: 'View documents',
    },
})

type ClosedOperationsProps = {
    operations: Operation[]
    showClosedByDefault: boolean
}

const ClosedOperations: FC<ClosedOperationsProps> = ({ operations, showClosedByDefault }) => {
    const [showClosed, setShowClosed] = useState(showClosedByDefault)
    const { t } = useClosedOperationsTranslation()

    const toggleShowClosedOperations = useCallback(() => {
        setShowClosed(val => !val)
    }, [])

    const toggleShowClosedButton = useMemo(() => {
        const message = showClosed ? t('hideOperations') : t('showOperations')
        return (
            <Divider plain>
                <Button ghost onClick={toggleShowClosedOperations}>
                    {message}
                </Button>
            </Divider>
        )
    }, [showClosed, t, toggleShowClosedOperations])

    if (operations.length === 0) {
        return null
    }

    if (!showClosed) {
        return toggleShowClosedButton
    }

    return (
        <>
            {toggleShowClosedButton}
            <ClosedOperationsTable operations={operations} />
        </>
    )
}

type ClosedOperationsTableProps = {
    operations: Operation[]
}

const ClosedOperationsTable: FC<ClosedOperationsTableProps> = ({ operations }) => {
    const { t } = useClosedOperationsTranslation()

    const columns = useMemo<FilterColumnType<Operation>[]>(
        () => [
            {
                title: t('operation'),
                render: (_: unknown, op) => op.name,
            },
            {
                render: (_: unknown, op) => <ClosedOperationsActions operation={op} />,
                align: 'right',
            },
        ],
        [t]
    )

    return (
        <Card title={t('closedOperations')}>
            <FilterTable<Operation> columns={columns} dataSource={operations} bordered={true} />
        </Card>
    )
}

type ClosedOperationsActionsProps = {
    operation: Operation
}

const ClosedOperationsActions: FC<ClosedOperationsActionsProps> = ({ operation }) => {
    const navigate = useNavigate()
    const { t } = useClosedOperationsTranslation()

    const goToDocuments = useCallback(() => {
        navigate(`${operation.id}/documents`)
    }, [operation.id, navigate])

    return (
        <Space>
            <ActionButton onClick={goToDocuments}>{t('accessDocuments')}</ActionButton>
        </Space>
    )
}
